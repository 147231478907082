/* non-pilot.scss */
.non-pilot-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  background-color: #f0f2f5; /* Light background color */
  padding: 20px;
}

.non-pilot-message {
  font-size: 1.5rem; /* Larger font size */
  color: #333; /* Darker text color */
  margin-bottom: 20px; /* Space between message and button */
}

.non-pilot-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff; /* Ant Design primary color */
  color: #fff; /* White text color */
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #40a9ff; /* Lighter blue on hover */
  }
}